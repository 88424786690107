<template>
  <div>
    <div class="fs-24 c-9 pd-40">
      <span @click="moreClick"
        ><img
          class="w-15 h-27 va-m mr-15"
          src="/static/images/new/icon-back.png"
          alt=""
      /></span>
      <span
        v-for="(item, index) in nav"
        :key="index"
        class="va-m"
        @click="navClick(item)"
        >{{ index == 0 ? "" : ">" }}{{ item.name }}</span
      >
    </div>
    <div class="plr-40">
      <h3 class="c-3 fs-30 fw-b bb-2-dcdcdc pb-15">{{ detailData.title }}</h3>
      <div class="c-9 fs-24 bb-2-dcdcdc h-60 dis-flex a-i-c">
        <span>{{ detailData.ext1 }}</span>
        <span class="ml-120">{{ detailData.author }}</span>
      </div>
      <div class="fs-24 c-6 ck-content" v-html="detailData.content"></div>
    </div>
    <div class="mt-80 pd-40 bg-fafafa">
      <div class="c-3 dis-flex j-c-b a-i-c pb-20 bb-1-dcdcdc">
        <h3 class="fs-34 fw-b">{{ $g("newDetailNav.title") }}</h3>
        <span class="fs-24" @click="moreClick"
          >{{ $g("newDetailNav.content")
          }}<img
            class="w-28 h-14 ml-20"
            src="/static/images/new/icon-more.png"
            alt=""
        /></span>
      </div>
      <ul class="">
        <li
          class="bb-2-f2f2f2 ptb-20"
          v-for="(item, index) in moreList"
          :key="index"
        >
          <p class="fs-24 c-9">{{ item.time }}</p>
          <router-link :to="{ path: '/new/detail', query: { id: item.id } }">
            <h3 class="fs-28 fw-b">{{ item.title }}</h3>
          </router-link>
          <p class="text-td-2 c-6 fs-24">{{ item.memo }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "NewDetail",
  data() {
    let me = this;
    return {
      detailData: {
        title: "",
        time: "",
        author: "",
        content: "",
      },
    };
  },
  computed: {
    nav() {
      let me = this;
      return [
        { name: me.$g("newDetailNav.ext1"), url: "/home" },
        { name: me.$g("newDetailNav.ext2"), url: "/new" },
      ];
    },
    moreList() {
      let me = this;
      if ( !me.$a.newArticle ){
        return [];
      }
      let liet = [];
      for(let i = me.$a.newArticle.length-1; i > 0; --i){
        if(liet.length > 2){
          break;
        }
        let art = me.$a.newArticle[i];
        if(art.id == me.detailData.id){
          continue;
        }
        liet.push(art);
      }
      return liet.reverse() || [];
    },
  },
  methods: {
    navClick(item) {
      this.$router.push({
        path: item.url,
      });
    },
    moreClick() {
      let me = this;
      me.$router.push("/new");
    },
    async articleDetail() {
      let me = this;
      me.id = me.$route.query.id;
      try {
        let d =
          (
            await axios.get("/official-page-web/data/mh-article/detail", {
              params: {
                id: me.id,
              },
            })
          ).data.data || {};
        me.detailData = d;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    let me = this;
    me.articleDetail();
  },
  watch: {
    $route(to, from) {
      let me = this;
      if (to.path == "/new/detail") {
        if (me.id != me.$route.query.id) {
          me.articleDetail();
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>